<template>
  <div v-if="isshowgrid">
    <va-card title="User List">
      <template slot="actions">
        <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
      </template>
      <div class="row">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>

  <div v-else-if='isshowForm'>
    <div>
      <va-card title="create User">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form @submit.prevent="isCreate ?createUser():updateUser()">
            <va-input
              v-model="role"
              type="text"
              label="role *"
              :options="role_obj"
              :disabled="isUpdate?true:false"
              required="true"
            />
            <va-input
              v-model="user_name"
              type="text"
              label="User Name *"
              pattern="^[A-Za-z]+$"
              placeholder="Enter User Name"
              required="true"
            />
            <va-input
              v-model="email"
              type="email"
              label="Email *"
              :disabled="isUpdate?true:false"
              required="true"
            />
            <va-input
              v-model="phone_number"
              type="telphone"
              label="Mobile Number *"
              pattern="[6-9]{1}[0-9]{9}"
              maxlength="10"
              placeholder="Enter Mobile Number"
              required="true"
            />
            <va-input
              v-model="password"
              type="password"
              label="Password *"
              placeholder="Enter Password"
              pattern="[A-Za-z0-9_@!./#&+-^]{8,}$"
              :required="isCreate?true:false"
            />
            <va-checkbox
              label="Status"
              v-model="status"
            />
            <div class="d-flex justify--center mt-3">
              <va-button type="submit" class="my-0" >{{isCreate ? 'Create':'Update'}}</va-button>
              <va-button type="submit" class="my-0" @click="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'users',
  beforeCreate () {
    this.$http.get(config.menu.host + 'api/user').then(response => {
      this.users = (response.body.length > 0) ? response.body : []
      this.isshowForm = false
      this.isshowgrid = true
      // this.getAllUser()
    }, err => {
      console.log('User get call error', err)
    })
  },
  data () {
    return {
      term: null,
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      users: [],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      role: [],
      user_name: '',
      email: '',
      password: '',
      status: true,
      phone_number: '',
      role_obj: [
        { id: 'SYS_ADMIN' },
        { id: 'MSO_ADMIN' },
      ],
    }
  },
  computed: {
    checkpassword () {
      if (this.password.length === 0) {
        return true
      } else if (this.password.length >= 8) {
        return true
      } else {
        return false
      }
    },
    fields () {
      return [{
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'user_name',
        title: 'User Name',
      },
      {
        name: 'email',
        title: 'Email',
      },
      {
        name: 'role',
        title: 'Role',
      },
      {
        name: 'phone_number',
        title: 'Phone Number',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      var gridData = search(this.term, this.users)
      console.log(gridData)
      var roleObject = {
        SYS_ADMIN: 'Sys Admin',
        MSO_ADMIN: 'Mso Admin',
      }
      if (gridData.length > 0) {
        gridData.map(function (item) {
          for (var key in roleObject) {
            if (item.role === key) {
              item.role = roleObject[key]
            }
          }
        })
      }
      return gridData
    },
  },
  methods: {
    getAllUser () {
      this.$http.get(config.menu.host + 'api/user').then(response => {
        this.users = (response.body.length > 0) ? response.body : []
        this.users.map(function (item) {
          item.status = (item.status === 1)
        })
        this.isshowgrid = true
      }, response => {
        console.log('USER ERR RESPONSE', response)
      })
    },
    createUser () {
      if (!this.role) {
        Vue.notify({
          text: 'Fill the form correctly',
          type: 'error',
        })
        return
      }
      var userStatus = this.status ? 1 : 0
      var payload = {
        user_name: this.user_name,
        email: this.email,
        password: this.password,
        phone_number: this.phone_number,
        role: this.role.id,
        status: userStatus,
      }
      this.$http.post(config.menu.host + 'api/user', payload).then(responseData => {
        if (responseData && responseData.body) {
          Vue.notify({
            text: responseData.body,
            type: 'success',
          })
        }
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          Vue.notify({
            text: errResponse.body,
            type: 'error',
          })
        }
        console.log('User Create ERR RESPONSE', errResponse)
      })
    },
    updateUser () {
      if (!this.role) {
        Vue.notify({
          text: 'Fill the form correctly',
          type: 'error',
        })
        return
      }
      var userStatus = this.status ? 1 : 0
      var payload = {
        status: userStatus,
        user_name: this.user_name,
        email: this.email,
        phone_number: this.phone_number,
      }
      if (this.password) {
        payload.password = this.password
      }
      this.$http.put(config.menu.host + 'api/user/' + this.user_id, payload).then(responseData => {
        if (responseData && responseData.body) {
          Vue.notify({
            text: responseData.body,
            type: 'success',
          })
        }
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          Vue.notify({
            text: errResponse.body,
            type: 'error',
          })
        }
      })
    },
    edit (row) {
      this.user_name = row.user_name
      this.email = row.email
      this.password = ''
      this.phone_number = row.phone_number
      this.status = !!row.status
      this.user_id = row.user_id
      this.role = row.role
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    add () {
      this.user_name = ''
      this.email = ''
      this.password = ''
      this.mobile_number = ''
      this.phone_number = ''
      this.role = ''
      this.status = true
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    list () {
      this.getAllUser()
      this.isshowForm = false
      this.isshowgrid = true
    },
    search: function (term) {
      console.log(term)
      this.term = term
    },
  },
}
</script>

<style lang="scss">
</style>
